@import "../../styles/constants.scss";

.modal-component-item {
  .modal-header {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .modal-content {
    padding: 0 !important;
    overflow: hidden;

    .confirmation-modal {
      box-sizing: border-box;
      min-width: 400px;
      padding: var(--space-unit-md) var(--space-unit-lg);
    }
  }

  .component-item-header {
    padding: var(--space-unit-lg);
    background-color: var(--color-black);
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-text-inverse);

    .component-item-header-description {
      font-size: var(--text-sm);
    }

    .item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 var(--space-unit-md);
      margin-top: var(--space-unit-lg);

      .item-text {
        margin-top: var(--space-unit-xs);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .component-item-groups {
    .component-item-group {
      padding: var(--space-unit-md);
      border-bottom: 1px solid var(--color-grey-grey50);

      &:last-child {
        border-bottom: none;
        border-radius: 10px;
      }

      .missing {
        font-size: var(--text-sm);
        color: var(--color-grey-grey100);
      }

      .component-item-group-chips {
        display: flex;
        flex-wrap: wrap;
        margin-top: var(--space-unit-xs);

        .component-item-group-chip {
          border-radius: 5px;
          padding: var(--space-unit-xxs) var(--space-unit-xs);
          border: 1px solid var(--color-grey-grey100);
          color: var(--color-grey-grey100);
          margin-right: var(--space-unit-xs);
          margin-bottom: var(--space-unit-xxs);
          margin-top: var(--space-unit-xxs);
          font-size: 14px;
        }
      }
    }
  }

  .component-item-footer {
    display: flex;
    justify-content: flex-end;
    padding: var(--space-unit-xs) var(--space-unit-lg);
  }
}
.export-data-modal {
  .modal-container {
    min-width: 600px;
  }

  .export-data-modal-content {
    padding: var(--space-unit-lg);

    .export-picker {
      margin-top: var(--space-unit-lg);
      margin-bottom: var(--space-unit-lg);
    }

    .DateRangePicker {
      width: 100%;

      * {
        font-family: var(--font-primary);
      }

      .DateRangePicker__Month {
        width: 40%;
      }
      .DateRangePicker__MonthHeader {
        font-size: 18px;
        font-weight: 400;
      }
      .DateRangePicker__WeekdayHeading {
        font-size: 12px;
        font-weight: 400;
      }
      .DateRangePicker__DateLabel {
        font-size: 15px;
        font-weight: 400;
      }

      .DateRangePicker__CalendarSelection {
        background-color: var(--color-black);
        border: var(--color-black);
      }

      .DateRangePicker__CalendarSelection--single {
        left: 0;
        right: 0;
      }

      .DateRangePicker__CalendarSelection--start {
        left: 0;
      }

      .DateRangePicker__CalendarSelection--end {
        right: 0;
      }

      .DateRangePicker__CalendarHighlight--single {
        border-color: var(--color-black);
        left: 0;
        right: 0;
      }
    }
  }

  .export-data-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .export-data-dates {
      margin-right: var(--space-unit-lg);
    }
  }
}
