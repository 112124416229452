@import "../../styles/constants.scss";
.beverage-merge-content {
  .beverage-merge-body {
    display: flex;
    flex: 1;
    margin: var(--space-unit-xl) calc(var(--space-unit-xs) * -1);
    flex-wrap: wrap;
    .beverage-merge-card {
      background-color: var(--color-white);
      border-radius: var(--border-radius);
      flex: 1;
      margin: var(--space-unit-xs);
      min-height: 190px;
      min-width: 350px;
      padding: var(--space-unit-md);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .beverage-merge-options {
        display: flex;
        justify-content: flex-end;

        @media (max-width: $mobile-breakpoint-3) {
          flex-direction: column;
        }
        @media (min-width: $mobile-breakpoint-3) {
          flex-wrap: wrap;
        }
        .select {
          flex: 1;
          @media (max-width: $mobile-breakpoint-3) {
            margin: calc(var(--space-unit-lg) / 2) 0;
          }
        }
        .button {
          flex: 1;
          margin: 0 calc(var(--space-unit-lg) / 2);
          @media (max-width: $mobile-breakpoint-3) {
            margin: calc(var(--space-unit-lg) / 2) 0;
          }
        }
        .buttons-container {
          display: flex;
          @media (max-width: $mobile-breakpoint-3) {
            flex-direction: column;
          }
        }
        .vertical-line {
          margin: 0 calc(var(--space-unit-lg) / 2);
          width: 1px;
          background-color: var(--color-grey-grey50);
        }
        .horizontal-line {
          margin: calc(var(--space-unit-lg) / 2) 0;
          height: 1px;
          background-color: var(--color-grey-grey50);
        }
      }
    }
  }
  .beverage-merge-description {
    .description-body {
      margin-top: var(--space-unit-md);
    }
  }
  .row {
    display: flex;
    .icon {
      margin-right: var(--space-unit-xs);
    }
  }
  .col {
    border: 1px solid black;
    height: 500px;
    min-width: 300px;
    max-width: 100%;
    flex: 1;
    .merge-col-header {
      display: flex;
      .merge-col-number {
        background-color: var(--color-primary);
        border-radius: 50%;
        padding: var(--space-unit-md);
      }
    }
  }
}
.beverage-details {
  overflow: initial;
}
.beverage-discard-guard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--space-unit-lg);
}
