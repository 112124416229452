.item {
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--space-unit-md);
  background-color: white;
  color: var(--color-black);
  flex: 1;
  .item-name-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    overflow: hidden;

    .item-icon {
      display: flex;
      flex-shrink: 0;
      margin-right: var(--space-unit-md);
    }
    .item-name {
      color: var(--color-primary);
    }
  }
  .item-rest {
    display: flex;
    align-items: center;

    .item-rest-details {
      display: flex;
      .item-rest-detail-title {
        color: var(--color-grey-grey100);
        margin-right: var(--space-unit-xs);
      }
      .item-rest-detail-value {
        color: var(--color-text);
        font-weight: 500;
      }
    }
    .item-rest-actions {
      display: flex;
      flex-direction: row;
      margin-left: var(--space-unit-lg);
      div {
        display: flex;
        cursor: pointer;
        margin-left: var(--space-unit-lg);

        .icon {
          stroke: var(--color-grey-grey100);
          &:hover {
            stroke: var(--color-text);
          }
        }
      }
    }
  }
}
