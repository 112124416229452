.beverage-card {
  border-radius: var(--border-radius);
  height: 225px;
  max-width: 680px;
  min-width: 340px;
  flex: 1;
  margin: 20px var(--space-unit-xs);
  background-color: var(--color-card-background);
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .beverage-card-inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .beverage-card-gradient-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 112px;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
  }
  .beverage-card-header {
    padding: var(--space-unit-lg);
    min-height: 64px;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    color: var(--color-white);
    max-height: 100%;
    width: 110%;
    &.with-logo {
      opacity: 0.75;
      //background-image: linear-gradient(90deg, #000000 1.71%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, #000000 1.71%, rgba(0, 0, 0, 0) 100%),  url("https://www.underconsideration.com/brandnew/archives/carlsberg_logo_evolution_video.jpg");
      background-position: center;
      background-size: cover;
    }
    &.no-logo {
      background-color: var(--color-grey-grey100);
    }
    .beverage-brand {
      font-weight: 500;
      font-size: var(--text-md);
    }
    .beverage-subbrand {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .beverage-card-content {
    padding: var(--space-unit-md) var(--space-unit-lg);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
  }
}
.beverages-subtitle-container {
  display: flex;
  .beverages-status-container {
    display: flex;
    align-items: center;
    margin-left: var(--space-unit-md);
    span {
      font-size: var(--text-sm);
      margin-left: var(--space-unit-md);
    }
    .value {
      font-size: var(--text-ssm);
      font-weight: 500;
      margin-left: 8px;
    }
  }
}

.beverage-approved-status {
  display: flex;
  align-items: center;
  font-size: var(--text-sm);
  margin-bottom: var(--space-unit-xs);
  .icon {
    margin-right: var(--space-unit-xs);
    width: 18px;
    height: 18px;
  }
}
.language-translation-container {
  display: flex;
  flex-wrap: wrap;
  .language-translation-progress-label {
    margin-right: var(--space-unit-xs);
    font-size: var(--text-sm);
  }
}
.language-translation-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  width: 30px;
  margin-right: var(--space-unit-xs);
  .language-translation-progress-background {
    position: absolute;
    bottom: 0px;
    height: 3px;
    background-color: var(--color-grey-grey100);
    width: 30px;
    left: 0px;
    border-radius: 1px;
    z-index: 0;
  }

  .language-translation-progress-bar {
    position: absolute;
    bottom: 0px;
    height: 3px;
    background-color: var(--color-success-success100);
    width: 12px;
    left: 0px;
    border-radius: 1px;
    z-index: 1;
  }
}
.beverage-country-list {
  display: flex;
  flex-wrap: wrap;

  &.extended-country {
    .country-flag-container {
      margin: var(--space-unit-xs);
    }
  }
  .country-flag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px var(--space-unit-xxs);
    .country-flag-label {
      margin-left: var(--space-unit-xs);
    }
  }
  .beverage-country-list-label {
    font-size: var(--text-sm);
    margin-right: var(--space-unit-xs);
  }
  .flag {
    border-radius: 50%;
    height: 18px;
    width: 18px;
  }
}
