@import "../../styles/constants.scss";

.overview-page {
  background-color: var(--color-background);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media (max-width: $mobile-breakpoint-2) {
    padding: 0;
  }

  .overview-assign {
    background-color: var(--color-background);
    border-radius: var(--border-radius);
    padding: var(--space-unit-lg);
    margin-bottom: var(--space-unit-xxl);
    display: flex;
    flex-direction: column;
    @media (max-width: $mobile-breakpoint-1) {
      padding: 0px;
    }
    @media (max-width: $mobile-breakpoint-2) {
      padding: 0 var(--space-unit-md);
    }
    .overview-assign-header {
      padding: var(--space-unit-lg);
      @media (max-width: $mobile-breakpoint-1) {
        display: flex;
        align-items: center;
        border-radius: var(--border-radius) var(--border-radius) 0px 0px;
        padding-bottom: var(--space-unit-xs);
      }
    }
    .overview-assign-content {
      padding: var(--space-unit-lg);
    }
  }

  .overview-cards {
    flex-grow: 1;

    @media (max-width: $mobile-breakpoint-1) {
      flex-direction: column;
      padding: 0 var(--space-unit-md);
      justify-content: stretch;
    }
    .overview-card {
      text-align: center;

      @media (max-width: $mobile-breakpoint-1) {
        flex: 1;
        padding: var(--space-unit-md);
        margin-bottom: var(--space-unit-lg);
        margin-right: 0px;
        width: auto;
      }
      width: calc(50% - var(--space-unit-lg));
      display: flex;
      text-align: center;
      flex-direction: column;
      max-height: 320px;
      justify-content: space-between;
      align-items: center;

      &:not(:last-of-type) {
        @media (min-width: $mobile-breakpoint-1) {
          margin-right: calc(2 * var(--space-unit-lg));
        }
      }

      .number-out-of {
        background-color: var(--color-background);
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        margin: var(--space-unit-lg) 0px;
        overflow: hidden;
      }

      .button {
        padding: var(--space-unit-md) var(--space-unit-xxxl);
      }
    }
  }
}
