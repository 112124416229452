.number-out-of {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .number-out-of-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 113px;
    .number-out-of-value {
      color: var(--color-text);
      font-size: 118px;
      font-weight: 700;
    }
    .number-out-of-total,
    .number-out-of-slash {
      color: var(--color-grey-grey100);
      font-size: 118px;
      font-weight: 400;
    }
  }
  .number-out-of-subtitle {
    color: var(--color-primary);
  }
}
