@import "../../../styles/constants.scss";

.video-upload {
  text-align: center;
  .logo-background {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-position: 50% 50%;
    background-size: cover;
    margin: var(--space-unit-md);
  }
  .video-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;

    .play-icon {
      position: absolute;
    }
  }
  .logo-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s background-color;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .icon {
      opacity: 0;
      transition: 0.3s opacity;
      position: absolute;
      &.plus-stroke {
        * {
          stroke: var(--color-black);
        }
      }
      &.no-video {
        opacity: 1;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      //opacity: 1;
      .icon {
        opacity: 1;
      }
    }

    &.logo-default {
      background-color: var(--color-grey-greenish);
      opacity: 1;

      .icon {
        display: none;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        .icon {
          display: initial;
        }
        span {
          display: none;
        }
      }
    }
  }

  .logo-button {
    text-decoration: underline;
    cursor: pointer;
    padding: 0 var(--space-unit-xs);
    &.logo-download {
      color: var(--color-keg-green);
    }
    &.logo-delete {
      color: var(--color-alert-alert100);
    }
  }
}
