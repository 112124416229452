@import "../../../styles/constants.scss";

.logo-upload {
  text-align: center;
  .logo-background {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-position: 50% 50%;
    background-size: cover;
    margin: var(--space-unit-md);
    position:relative;
  }
  .logo-overlay {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s background-color;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    .icon {
      opacity: 0;
      transition: 0.3s opacity;
      position: absolute;
    }
    .initials {
      opacity: 1;
      transition: 0.3s opacity;
      font-weight: 500;
      font-size: 68px;
      line-height: 73px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      //opacity: 1;
      .icon {
        opacity: 1;
      }
      .initials {
        //opacity:0;
      }
    }

    &.logo-default {
      background-color: rgba(255, 255, 255, 0.75);
      opacity: 1;

      .icon {
        display: none;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        .icon {
          display: initial;
        }
        span {
          display: none;
        }
      }
    }
  }

  .logo-button {
    text-decoration: underline;
    cursor: pointer;
    padding: 0 var(--space-unit-xs);
    &.logo-download {
      color: var(--color-keg-green);
    }
    &.logo-delete {
      color: var(--color-alert-alert100);
    }
  }
}
