@import "../../../styles/constants.scss";
.card-item {
  width: 100%;
  background-color: var(--color-card-background);
  border-radius: var(--border-radius);

  .card-item-header {
    box-sizing: border-box;
    padding: var(--space-unit-lg);
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .card-icons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .card-item-actions {
        display: flex;
        flex-direction: row;
        div {
          display: flex;
          cursor: pointer;
          margin-left: var(--space-unit-lg);

          .icon {
            stroke: var(--color-grey-grey100);
            &:hover {
              stroke: var(--color-text);
            }
          }
        }
      }
    }
    .card-item-mobile-menu {
      display: flex;
      margin-left: var(--space-unit-lg);
      @media (min-width: $mobile-breakpoint-1) {
        display: none;
      }
    }
    .card-item-header-right {
      margin-left: var(--space-unit-lg);
      .icon {
        display: flex;
        width: 34px;
      }
    }
  }
  .card-item-details {
    padding: var(--space-unit-md) var(--space-unit-lg);
    flex: 1 1;
    .card-item-detail {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: hidden;
      .card-item-detail-title {
        color: var(--color-grey-grey100);
        white-space: nowrap;
      }
      .card-item-detail-value {
        color: var(--color-black);
        margin-left: var(--space-unit-xs);
        white-space: nowrap;
        font-weight: 500;
      }
    }
  }
}
