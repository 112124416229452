:root {
  --border: 2px;
  --border-radius: 10px;
  --transition-duration: 100ms;

  // ---- DETAIL PAGE ----
  --detail-page-header-height: 192px;
  --detail-page-header-height-mobile: 67px;
}

$mobile-breakpoint-1: 900px;
$mobile-breakpoint-2: 700px;
$mobile-breakpoint-3: 1200px;
