.container {
  width: auto;
  border: solid 1px;
}
.containerOutlined {
  border-radius: 50px;
  padding: 0 var(--space-unit-xs);
  background-color: transparent;
}

.containerFilled {
  border-radius: 50px;
  padding: 0 var(--space-unit-xs);
}

.badgeText {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.countSeparator {
  color: var(--color-text);
}
