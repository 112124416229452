@import "../../styles/constants.scss";

.beverage-form {
  display: flex;
  flex-direction: column;
  position: relative;
  .beverage-form-container {
    display: flex;
    flex-direction: column;
    padding: 0 var(--space-unit-xxxl);
    position: relative;
    max-width: 450px;

    @media (max-width: $mobile-breakpoint-1) {
      position: initial;
      padding: var(--space-unit-lg);
    }
  }
  .beverage-form-submit-container-parent {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;

    @media (max-width: $mobile-breakpoint-1) {
      width: 100%;
      position: initial;
    }
    .beverage-form-submit-container {
      position: sticky;
      top: var(--space-unit-lg);
      right: 0;
      box-sizing: border-box;
      z-index: 1;
      max-width: 325px;

      @media (max-width: $mobile-breakpoint-1) {
        max-width: calc(100% - var(--space-unit-lg) * 2);
        margin: auto;
      }
    }

    .beverage-form-submit-container-language {
      background-color: var(--color-white);
      border-radius: var(--border-radius);
      padding: var(--space-unit-xxl);
      position: sticky;
      top: var(--space-unit-lg);
      right: 0;
      box-sizing: border-box;
      z-index: 1;

      p {
        font-size: var(--text-sm);
        font-weight: 400;
      }

      .beverage-form-checkbox-not-mobile {
        display: initial;
      }
      .beverage-form-checkbox-mobile {
        display: none;
      }

      @media (max-width: $mobile-breakpoint-1) {
        position: initial;
        margin-bottom: var(--space-unit-lg);

        .beverage-form-checkbox-not-mobile {
          display: none;
        }
        .beverage-form-checkbox-mobile {
          display: initial;
        }
      }
    }

    .beverage-form-submit-button-container {
      width: 100%;
      text-align: center;
      padding: var(--space-unit-lg) 0;
      box-sizing: border-box;

      button {
        width: 100%;
      }
    }
  }
}

.beverage-input {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-unit-xl);

  .question {
  }
  .description {
    font-size: var(--text-sm);
    font-weight: 400;
    margin-bottom: var(--space-unit-lg);
  }
  .subquestion {
    font-family: var(--button-font-family);
    font-size: var(--button-text-size);
    font-weight: var(--button-text-weight);
  }

  .beverage-textfield {
    margin-bottom: var(--space-unit-lg);

    .MuiInputLabel-shrink {
      font-size: var(--text-size) !important;
    }

    &.multiline {
      height: auto;
    }
    &:last-of-type {
      margin-bottom: var(--space-unit-md);
    }
  }

  .beverage-form-logo-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-1) {
      flex-direction: column;
    }
  }
}
.beverage-form-checkbox-not-mobile {
  display: initial;
}
.beverage-form-checkbox-mobile {
  display: none;
}

@media (max-width: $mobile-breakpoint-1) {
  .beverage-form-checkbox-not-mobile {
    display: none;
  }
  .beverage-form-checkbox-mobile {
    display: initial;
  }
}
