@import "./constants.scss";
@import "./colors.scss";
@import "./fonts.scss";
@import "./layout.scss";
@import "./typography.scss";
@import "./animations.scss";
@import "~@ddm-design-system/tokens/index.scss";

/* tailwind configuration */
@tailwind base;
@layer base {
  *,
  ::after,
  ::before {
    box-sizing: initial;
  }
}
@tailwind components;
@layer components {
  .field-wrapper {
    @apply flex flex-row items-start justify-between xs:flex-col;
  }
  .field-section {
    @apply flex flex-col flex-1;
  }
  .field {
    @apply flex flex-col pt-xl justify-center;
  }
}

@tailwind utilities;
