@import "../../../styles/animations.scss";

svg {
  .gray {
    fill: var(--color-grey-grey50);
  }
  .warning {
    fill: var(--color-warning-warning100);
  }
  .danger {
    fill: var(--color-alert-alert100);
  }
}
