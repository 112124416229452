@import "../../../styles/constants.scss";

.button,
.MuiButton-root.button {
  --button-color-text: var(--color-text);
  --button-color-background: var(--color-background);
  --button-font-family: var(--font-family);
  --button-text-size: var(--text-base-size);
  --button-text-weight: 500;
  --button-border-radius: 90px;
  --button-border-width: 0;
  --button-border-style: solid;
  --button-border-color: var(--color-border);
  --button-text-transform: none;
  --button-height: 50px;
  --button-min-width: 215px;
  --button-padding-vertical: 16px;

  background-color: var(--button-color-background);
  border-radius: var(--button-border-radius);
  border-width: var(--button-border-width);
  border-style: var(--button-border-style);
  border-color: var(--button-border-color);
  color: var(--button-color-text);
  font-family: var(--button-font-family);
  font-size: var(--button-text-size);
  font-weight: var(--button-text-weight);
  height: var(--button-height);
  line-height: 1;
  min-width: var(--button-min-width);
  text-transform: var(--button-text-transform);
  white-space: nowrap;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: var(--button-height);

  @media (max-width: $mobile-breakpoint-1) {
    --button-min-width: none;
  }
  a {
    color: var(--button-color-white);
  }

  &.inverse {
    --color-text: var(--color-text-inverse);
    --color-background: var(--color-background-inverse);
  }

  &.inline {
    --button-min-width: none;
  }

  &.warning {
    --button-color-text: var(--color-warning-warning100);
  }

  &.error {
    background-color: var(--color-alert-alert100);
    --button-color-text: var(--color-white);
  }

  &.disabled {
    background-color: var(--button-color-background);
    border-width: var(--button-border-width);
    color: var(--button-color-text);
  }

  &.button-primary {
    --button-color-background: var(--color-primary);
    --button-color-text: var(--color-text-inverse);

    &.disabled {
      --button-color-background: var(--color-grey-grey50);
      --button-color-text: var(--color-white);
    }
  }

  &.button-secondary {
    --button-border-color: var(--color-primary);
    --button-border-width: 2px;
    --button-color-background: var(--color-transparent);
    --button-color-text: var(--color-primary);

    &.disabled {
      --button-border-color: var(--color-grey-grey100);
      --button-color-text: var(--color-grey-grey100);
    }
  }

  &.button-ghost {
    --button-color-background: var(--color-transparent);

    &.disabled {
      --button-color-text: var(--color-grey-grey100);
    }
  }

  &.button-header {
    --button-border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --button-text-size: var(--text-md);
    --button-color-background: var(--color-button-header-background);
    --button-color-background-hover: lighten(#161618, 5);
    --button-color-text: var(--color-text-inverse);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--button-color-background-hover);
    }

    &.inverse {
      --button-color-background: var(--color-text);
      --button-color-text: var(--color-background);
      --button-color-background-hover: var(--color-grey-grey50);
    }
  }
}
