@import "../../styles/constants";

@media (max-width: $mobile-breakpoint-2) {
  .page-list {
    .detail-page-header {
      .detail-page-header-content.mobile {
        display: none;
      }
    }
  }
}

.associate-device {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-error);
  color: var(--color-text-inverse);
  border-radius: var(--border-radius);
  margin-top: var(--space-unit-xxl);
  padding: var(--space-unit-lg);

  @media (max-width: $mobile-breakpoint-2) {
    margin-bottom: var(--space-unit-xxl);
  }

  .associate-device-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--space-unit-md);
    .associate-icon {
      margin-right: var(--space-unit-md);
    }
  }
  .authenticator-code-area {
    background-color: var(--color-background-inverse);
    margin-top: var(--space-unit-lg);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: var(--space-unit-lg);
    transform: scale(0, 1);
    animation: expand-horizontally forwards 0.5s 0.3s;

    .authenticator-code-value {
      margin-left: var(--space-unit-xxs);
    }
  }
}

.technician-devices {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-unit-xxl);
  @media (max-width: $mobile-breakpoint-2) {
    margin-top: 0px;
  }

  .technician-devices-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .technician-devices-associated {
      margin-right: 40px;
    }

    .technician-add-device-text {
      color: var(--color-primary);
    }

    .technician-add-device-code {
      font-weight: 500;
      margin-left: var(--space-unit-md);
    }
  }

  .technician-devices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: var(--space-unit-md);
    margin-top: var(--space-unit-md);
    @media (max-width: $mobile-breakpoint-2) {
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
  }
}

.technician-outlets {
  margin-top: var(--space-unit-xxl);
  @media (max-width: $mobile-breakpoint-2) {
    margin-top: var(--space-unit-lg);
  }

  .technician-outlets-list {
    margin: var(--space-unit-md) 0px;
    .item,
    .card-item {
      opacity: 0;
      animation: 0.3s forwards fade-in;
      margin-top: var(--space-unit-xs);

      @media (max-width: $mobile-breakpoint-2) {
        margin-top: var(--space-unit-md);
      }

      &.technician-ce-item {
        color: var(--color-primary);

        .MuiCheckbox-root {
          padding-left: 0px;
          color: var(--color-black);
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .technician-outlets-associate-button {
    --button-border-radius: var(--border-radius);
    margin-top: var(--space-unit-md);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: flex-start;
    .icon {
      margin-left: var(--space-unit-lg);
      margin-right: var(--space-unit-md);
    }
  }
}

.technicians-page {
  a:-webkit-any-link {
    text-decoration: none;
  }
}

.modal-create-technician {
  min-width: 530px;

  @media (max-width: $mobile-breakpoint-2) {
    min-width: unset;
  }

  .textfield {
    width: 100%;
  }
  .text-form {
    width: 100%;
  }
  .form-option {
    align-self: flex-end;
    .form-option-cancel {
      border-radius: 29px;
      color: var(--color-alert-alert100);
      margin-right: var(--space-unit-lg);
      padding: 0px var(--space-unit-lg);
    }
  }
}
.authenticator-code-modal-requesting {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  min-height: 250px;
}
.authenticator-code-modal {
  display: flex;
  min-height: 300px;
  min-width: 550px;
  flex-direction: column;

  @media (max-width: $mobile-breakpoint-2) {
    min-width: unset;
  }

  .authenticator-code-modal-title {
    font-weight: 700;
  }

  .authenticator-code-modal-subtitle {
    color: var(--color-grey-grey100);
  }
  .authenticator-code-modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .authenticator-code-modal-content-value {
      font-weight: 500;
    }
  }
  .authenticator-code-modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}
.new-technician {
  .new-technician-form-container {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: space-around;
    align-items: flex-start;
  }
}

.technician-detail-page {
  .attribute-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .technician-attribute {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: var(--space-unit-xl);
    word-break: break-all;
    white-space: nowrap;
    min-width: 140px;
    margin-right: var(--space-unit-md);
    margin-bottom: var(--space-unit-md);

    a:-webkit-any-link {
      text-decoration: none;
    }
  }

  .technician-schedule {
    position: relative;
    border-top: 1px solid black;

    .schedule-button {
      position: absolute;
      top: -20px;
      right: 30px;
    }
  }

  .technician-section {
    margin: var(--space-unit-lg) var(--space-unit-xl) var(--space-unit-xxl) var(--space-unit-xl);

    .section-row-header {
      color: #ccc;
      display: flex;
      flex-direction: row;

      span {
        flex: 0.25;
        text-align: center;
      }
    }

    .no-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: var(--space-unit-xl);
      justify-content: center;
    }
  }
}

.schedule-item {
  height: 25px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--space-unit-md);
  margin: 2px;
  border: 1px solid #bbb;

  span {
    flex: 0.25;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.device-detail-page {
  .device-detail-content {
    display: flex;
    flex-direction: row;
    height: 350px;
  }

  .device-detail-current-device {
    flex: 0.3;
    font-size: var(--space-unit-lg);
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bbb;
  }

  .device-detail-code {
    flex: 0.7;
    color: steelblue;
    font-size: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bbb;
  }
}
