@import "./constants.scss";

:root {
  --font-primary: "BrandonGrotesque";

  --text-base-size: 18px;
  --text-scale: 1.33em;

  --text-xs: 0.563em;
  --text-sm: 0.75em;
  --text-md: 1.33em;
  --text-lg: 2.369em;
  --text-xl: 3.157em;

  --heading-line-height: var(--text-scale);
  --body-line-height: var(--text-scale);

  @media (max-width: $mobile-breakpoint-1) {
    --text-lg: 1.77em;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: var(--heading-line-height);
}

h1 {
  font-size: var(--text-lg);
  font-family: var(--font-primary);
  font-weight: 500;
}

h2 {
  font-size: var(--text-md);
  font-family: var(--font-primary);
  font-weight: 700;
}

h4 {
  font-size: var(--text-ssm);
  font-weight: 500;
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  font-weight: 400;
  color: var(--color-text);
}

.description {
  font-size: var(--text-sm);
  font-weight: 400;
}

small {
  font-size: var(--text-xs);
}

button,
.button {
  font-size: var(--text-base-size);
  font-weight: 500;
  line-height: 1;
  .MuiButton-label {
    letter-spacing: 0px;
  }
}

.text--xl {
  font-size: var(--text-xl);
}

.text--lg {
  font-size: var(--text-lg);
}

.text--md {
  font-size: var(--text-md);
}

.text--sm {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
}

h5 {
  font-size: var(--text-base-size);
  font-weight: 500;
}

b,
strong {
  font-weight: 700;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
