@import "../../../styles/constants.scss";
.toggle-button-group {
  padding: var(--space-unit-md) var(--space-unit-lg);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  box-sizing: border-box;

  .toggle-button-group-item {
    border-radius: var(--border-radius);
    color: var(--color-black);
    background-color: var(--color-white);
    border: 1px solid var(--color-primary);
    cursor: pointer;
    padding: var(--space-unit-md);
    margin: var(--space-unit-xs) 0;
    width: 100%;
    outline: none;

    &.selected {
      color: var(--color-white);
      background-color: var(--color-primary);
    }

    &.disabled.selected {
      background-color: var(--color-grey-grey100);
      border-color: var(--color-grey-grey100);
      color: var(--color-white);
      cursor: not-allowed;
    }

    &.disabled:not(.selected) {
      background-color: var(--color-grey-grey50);
      border-color: var(--color-grey-grey50);
      color: var(--color-white);
      cursor: not-allowed;
    }
  }
}
