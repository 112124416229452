.custom-menu {
  padding: 0px;

  ul {
    padding: 0px;
  }

  .MuiPaper-root {
    padding: 0px;
  }

  .MuiPaper-rounded {
    border-radius: var(--border-radius);
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

    .menu-option {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px var(--space-unit-xs);

      .button {
        padding: 0px;
        margin: 0px;
      }

      svg {
        stroke: var(--color-white);
      }
      span {
        margin-left: var(--space-unit-xs);
      }
    }
    &.inverted {
      .menu-option {
        svg {
          stroke: var(--color-black);
        }
      }
    }
  }
}
.icon {
  &.inverted {
    fill: var(--color-white);
  }
}
