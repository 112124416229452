@import "../../../styles/constants.scss";
.checkbox-group {
  background-color: var(--color-white);
  padding: var(--space-unit-md) var(--space-unit-lg);
  border-radius: var(--border-radius);
  display: flex;
  flex-grow: 1;
  align-items: center;
  box-sizing: border-box;

  &.checkbox-group-modal {
    background-color: transparent;
    padding: 0px;
  }

  &.checkbox-group-show-all {
    padding-top: 0;
    padding-bottom: 0;
  }

  .checkbox-group-title {
    margin-right: var(--space-unit-lg);
  }

  .checkbox-group-label {
    font-weight: 500;
  }
  .checkbox-group-button.selected {
    background-color: var(--color-black);
    color: white;
    padding: var(--space-unit-xs) var(--space-unit-xs);
    border-radius: var(--border-radius);
    margin-left: var(--space-unit-xs);
    height: auto;
    //max-height: 32px;
    min-height: 32px;
    min-width: 40px;

    .MuiButton-label {
      white-space: initial;
    }
  }

  .checkbox-group-item {
    color: var(--color-grey-grey100);
    margin: 0px var(--space-unit-lg) 0px 0px;

    input {
      cursor: pointer;
      margin-right: var(--space-unit-xs);
    }

    label {
      cursor: pointer;
      display: flex;
      user-select: none;
      align-items: center;
    }

    &.selected,
    &:hover {
      color: var(--color-text);
    }
  }
  .checkbox-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
