.textfield,
.MuiTextField-root.textfield {
  --textfield-color-text: var(--color-text);
  --textfield-underline-color: var(--color-grey-grey50);
  --textfield-underline-color-focused: var(--color-text);
  --textfield-label-color: var(--color-grey-grey100);
  --textfield-label-color-focused: var(--color-text);
  --textfield-underline-color-focused: var(--color-text);
  --textfield-border-width: 1px;
  --textfield-height: 50px;
  --textfield-font-weight: 400;

  box-sizing: border-box;
  color: var(--textfield-color-text);
  height: var(--textfield-height);
  font-family: var(--font-primary);

  .MuiInput-root {
    height: 100%;
    line-height: 1;
    font-family: var(--font-primary);
    font-size: var(--text-base-size);

    &:before {
      border-color: var(--textfield-underline-color);
      border-width: var(--textfield-border-width);
      border-top: none;
      border-right: none;
      border-left: none;
    }

    &:hover:before {
      border-color: var(--textfield-underline-color);
      border-bottom-width: var(--textfield-border-width);
    }

    &:after {
      border-color: var(--textfield-underline-color-focused);
      border-width: var(--textfield-border-width);
    }

    &.Mui-error:after {
      border-color: var(--color-alert-alert100);
    }
  }

  .MuiFormLabel-root {
    color: var(--textfield-label-color);
    font-family: var(--font-primary);
    font-size: var(--text-size);

    &.Mui-focused,
    &.MuiFormLabel-filled {
      color: var(--textfield-label-color-focused);
      font-size: var(--text-md);
    }
  }

  .MuiInputBase-input {
    font-weight: var(--textfield-font-weight);
    line-height: var(--body-line-height);
  }

  .MuiFormHelperText-root {
    text-align: right;

    &.Mui-error {
      color: var(--color-alert-alert100);
    }
  }
}
