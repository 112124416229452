@import "../../../styles/constants.scss";

.detail-page {
  .mobile {
    display: none;
    @media (max-width: $mobile-breakpoint-2) {
      display: block;
    }
  }

  .desktop {
    @media (max-width: $mobile-breakpoint-2) {
      display: none;
    }
  }

  .detail-page-header {
    background-color: var(--color-background-inverse);
    color: var(--color-text-inverse);
    padding: 0 var(--space-unit-lg) var(--space-unit-lg);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: var(--detail-page-header-height);
    position: relative;
    margin-bottom: var(--space-unit-lg);
    background-size: cover;
    background-position: 50% 50%;

    .detail-page-header-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 60%;
      height: 100%;
      background-size: cover;
      background-position: 50% 40%;
      margin-left: 40%;
      box-sizing: border-box;
    }

    .button.button-secondary {
      border-color: var(--color-text-inverse);
      color: var(--color-text-inverse);
    }

    .icon {
      stroke: var(--color-text-inverse);
      min-width: 34px;
    }

    &.inverse {
      background-color: var(--color-background);
      color: var(--color-primary);

      .button.button-secondary {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }

      .detail-page-actions {
        padding-top: var(--space-unit-xs);
      }

      .icon {
        stroke: var(--color-primary);
      }
    }

    @media (max-width: $mobile-breakpoint-2) {
      min-height: var(--detail-page-header-height-mobile);
      border-radius: 0px;
      padding: 0px var(--space-unit-md);
      margin-bottom: var(--space-unit-md);
      justify-content: center;

      &.inverse {
        background-color: var(--color-text-inverse);
      }
    }

    .detail-page-header-left {
      margin-right: var(--space-unit-md);
    }

    .detail-page-header-right {
      flex-shrink: 0;
    }

    .detail-page-header-title {
      font-size: var(--text-lg);
      font-weight: 500;
      margin-bottom: var(--space-unit-xs);
      display: flex;
      align-items: center;
    }

    .detail-page-actions {
      padding-top: var(--space-unit-xs);
      @media (max-width: $mobile-breakpoint-2) {
        display: flex;
        flex-direction: column;
        padding-top: 0px;
        margin-bottom: var(--space-unit-md);
        align-items: center;
      }
      .button.button-secondary {
        &:not(:first-of-type) {
          margin-left: var(--space-unit-md);

          @media (max-width: $mobile-breakpoint-2) {
            margin: var(--space-unit-md) auto;
          }
        }
      }
    }

    .detail-page-header-heading {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      z-index: 1;
      .show-mobile {
        display: none;
        @media (max-width: $mobile-breakpoint-2) {
          display: initial;
        }
      }

      &.mobile {
        display: none;
        justify-content: space-between;
        align-items: center;
        min-height: var(--detail-page-header-height-mobile);
        flex-wrap: nowrap;
      }

      @media (max-width: $mobile-breakpoint-2) {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: flex;
        }
      }

      .button {
        border-color: var(--color-text-inverse);
        color: var(--color-text-inverse);

        .icon {
          margin-right: var(--space-unit-xs);
        }
      }
    }

    .detail-page-header-content {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      z-index: 1;

      &.mobile {
        display: none;
        margin-bottom: var(--space-unit-xs);
        align-items: center;
        margin-top: var(--space-unit-md);
      }

      @media (max-width: $mobile-breakpoint-2) {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: flex;
        }
      }
    }

    .detail-page-header-back,
    .detail-page-header-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .detail-page-content {
    @media (max-width: $mobile-breakpoint-2) {
      padding: var(--space-unit-md);
    }
  }
}

.hidden-icon {
  opacity: 0;
}
.page-list {
  .page-list-header {
    @media (max-width: $mobile-breakpoint-1) {
      flex-direction: column !important;
      align-items: stretch !important;
      padding-top: var(--space-unit-lg);
    }
    @media (max-width: $mobile-breakpoint-2) {
      align-items: stretch !important;
      padding-top: 0;
    }
    .page-list-header-container {
      @media (max-width: $mobile-breakpoint-2) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1 1;
        .icon {
          stroke: var(--color-text-inverse);
          margin: 0 var(--space-unit-lg);
        }
      }
      @media (min-width: $mobile-breakpoint-2) {
        .icon {
          display: none;
        }
      }
    }
    @media (max-width: $mobile-breakpoint-2) {
      .button {
        display: none;
      }
    }
  }
  .page-list-header-subtitle {
    @media (max-width: $mobile-breakpoint-2) {
      display: none;
    }
  }
}
