@import "../../../styles/constants.scss";

.modal-associate-technician {
  .modal-container {
    width: 100%;
    max-width: 895px;
  }

  .select.select-search {
    flex: 1 1;
    .select-header {
      background-color: var(--color-white);
    }
  }

  .button {
    flex: 0;
  }
}

.associate-technician {
  align-items: stretch;
  @media (max-width: $mobile-breakpoint-1) {
    flex-direction: column;
  }

  .select {
    @media (max-width: $mobile-breakpoint-1) {
      width: 100%;
      margin-bottom: var(--space-unit-md);
    }
    .select-header {
      background-color: var(--color-white);
    }
  }
  .button {
    flex: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 50px;
  }
}
