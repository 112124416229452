@import "../../../styles/constants.scss";

.filter {
  .filter-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: var(--space-unit-lg);
    justify-content: space-between;

    @media (max-width: $mobile-breakpoint-1) {
      align-items: flex-start;
      padding-top: 0;
    }

    .filter-sort {
      display: flex;
      //flex-basis: 290px;
      align-items: center;
      margin-left: var(--space-unit-lg);
      min-width: 300px;

      @media (max-width: $mobile-breakpoint-1) {
        order: 1;
        margin-left: var(--space-unit-xs);
        width: 100%;

        &.beverage-filter {
          order: 2;
          width: 100%;
        }
      }
    }

    .filter-select {
      flex: 1;
      margin-left: var(--space-unit-xs);
      --select-border-color: transparent;

      &.active {
        .select-header {
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        }
        .select-options {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        }
      }

      .select-header {
        background-color: var(--color-white);
      }

      .select-content {
        min-width: unset;
      }
    }

    .filter-categories {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      margin-top: var(--space-unit-md);

      @media (max-width: $mobile-breakpoint-1) {
        flex-basis: auto;
        order: 1;
        margin-top: 0px;
        margin-bottom: var(--space-unit-md);
      }
    }
  }
}
