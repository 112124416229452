@import "../../../styles/constants.scss";

.select {
  cursor: pointer;
  display: flex;
  --select-item-height: 50px;
  --select-border-color: var(--color-grey-grey50);

  &.disabled {
    pointer-events: none;
  }

  .icon {
    margin-left: var(--space-unit-xs);
  }

  &.select-search {
    --icon-size: 25px;
    .icon {
      height: var(--icon-size);
      width: var(--icon-size);
      margin-left: 0px;
    }
  }
  .select-label {
    font-weight: 500;
    margin: 0px 10px 0px 0px;
  }

  .select-content {
    flex-grow: 1;
    overflow: visible;
    position: relative;
    max-width: 100%;
  }

  .select-placeholder {
    line-height: 1;
    margin-right: var(--space-unit-xs);
  }

  .select-header {
    align-items: center;
    background-color: var(--color-grey-grey25);
    border-radius: 29px;
    border: 1px solid var(--select-border-color);
    color: var(--color-grey-grey100);
    display: flex;
    justify-content: space-between;
    height: calc(var(--select-item-height) - 2px);
    padding: 0 var(--space-unit-lg);
    transition: border-color var(--transition-duration), background-color var(--transition-duration),
      border-radius var(--transition-duration) calc(var(--transition-duration) / 4);

    &.selected {
      background-color: var(--background-color);
      color: var(--color-text);
    }

    .select-search-textfield {
      align-self: flex-start;
      flex-direction: row;
      box-sizing: border-box;
      margin-top: calc(var(--space-unit-xs) / 2);
      overflow: hidden;
      height: calc(var(--select-item-height) - var(--space-unit-xs));
      z-index: 1;
      width: calc(100% - var(--icon-size) - var(--space-unit-xs));

      .select-search-input {
        font-family: var(--font-primary);
        font-size: var(--text-base-size);
        margin-top: 10px;
        padding: 0px;
        position: absolute;
        bottom: 0px;
        top: 0px;
        width: 100%;
      }

      .select-search-label {
        color: var(--color-grey-grey100);
        font-size: var(--text-sm);
      }

      .select-search-text {
        font-size: var(--text-base-size);
        margin-top: 13px;
        box-sizing: border-box;
        color: transparent;

        .select-search-suggestion {
          color: var(--color-grey-grey50);
        }
      }
    }
  }

  &:hover {
    .select-header {
      border-color: var(--color-grey-grey100);
    }
  }

  &.active {
    .select-header {
      background-color: var(--background-color);
      border-color: var(--select-border-color);
      transition: border-color var(--transition-duration),
        background-color var(--transition-duration), border-radius var(--transition-duration) 0ms;
    }

    &.options {
      .select-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .select-options {
    background-color: var(--color-white);
    position: absolute;
    left: 0px;
    right: 0px;
    opacity: 0;
    pointer-events: none;
    border: 1px solid var(--select-border-color);
    border-bottom-left-radius: 29px;
    border-bottom-right-radius: 29px;
    border-top-width: 0px;
    max-height: calc(var(--select-item-height) * 5);
    overflow-y: auto;
    transition: opacity var(--transition-duration),
      transform var(--transition-duration) calc(var(--transition-duration) / 4);
    transform: translate(0px, -10px);
    z-index: 2;

    &::-webkit-scrollbar {
      width: 21px;
    }

    &::-webkit-scrollbar-track {
      margin: calc(var(--select-item-height) / 4);
      border: 8px solid transparent;
      background-clip: padding-box;
      border-radius: 12px;
      background-color: var(--color-grey-grey50);
    }

    &::-webkit-scrollbar-thumb {
      border: 8px solid transparent;
      background-clip: padding-box;
      border-radius: 12px;
      background-color: var(--color-grey-grey100);
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &.visible {
      opacity: 1;
      pointer-events: all;
      transform: translate(0px, 0px);
      transition: opacity var(--transition-duration) calc(var(--transition-duration) / 2),
        transform var(--transition-duration) calc(var(--transition-duration) / 4);
    }

    .select-options-item {
      background-color: var(--color-white);
      color: var(--color-grey-grey100);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      min-height: var(--select-item-height);
      padding: var(--space-unit-xs) var(--space-unit-xs) var(--space-unit-xs) var(--space-unit-lg);
      transition: color var(--transition-duration), background-color var(--transition-duration);
      line-height: 100%;

      &:hover,
      &.suggested {
        background-color: var(--color-grey-grey25);
      }

      &.selected {
        color: var(--color-text);
      }

      &.disabled {
        color: var(--color-grey-medium);
        background-color: var(--color-white);
        pointer-events: none;
        cursor: initial;
      }
    }
  }
}
