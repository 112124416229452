:root {
  --color-keg-green: #00321e;
  --color-lager-yellow: #ffb400;
  --color-white: #ffffff;
  --color-black: #070707;
  --color-grey-light: #f6f6f6;
  --color-grey-medium: #cccccc;
  --color-grey-dark: #808080;
  --color-grey-greenish: #e5ebe8;

  --color-primary: var(--color-keg-green);
  --color-primary-dark: #002415;
  --color-primary-light: #004228;
  --color-secondary: var(--color-lager-yellow);
  --color-transparent: "transparent";
  --color-background: #f8f8f8;
  --color-card-background: #efefef;
  --color-overlay: rgba(4, 4, 15, 0.7);

  --color-text: var(--color-black);
  --color-border: var(--color-grey-grey50);
  --color-link: var(--color-primary);

  --color-background-error: #e8aeae;
  --color-background-inverse: var(--color-black);
  --color-text-inverse: var(--color-white);

  --color-sidebar-background: var(--color-white);
  --color-button-header-background: #161618;
}

.theme-inverse {
  --color-background: var(--color-background-inverse);
  --color-text: var(--color-text-inverse);
}
