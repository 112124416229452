@import "../../../styles/constants.scss";

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  padding: var(--space-unit-xl);
  box-sizing: border-box;

  &.visible {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .modal-overlay {
    opacity: 0;
    animation: fade-in forwards 0.2s;
    background-color: var(--color-overlay);
    height: 100%;
    position: absolute;
    z-index: 0;
    width: 100%;
  }

  .modal-container {
    animation: expand 0.1s forwards;
    background-color: var(--color-background);
    border-radius: var(--border-radius);
    display: inline-block;
    max-width: 100%;
    position: relative;
    z-index: 1;

    .modal-header {
      background-color: var(--color-background-inverse);
      border-radius: var(--border-radius);
      color: var(--color-text-inverse);
      overflow: hidden;
      padding: var(--space-unit-md) 0 var(--space-unit-md) var(--space-unit-lg);
      display: flex;
      justify-content: space-between;
      align-content: center;

      .modal-header-title {
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .modal-header-close {
        display: flex;
        align-items: center;
        align-self: stretch;
        cursor: pointer;
        padding-left: var(--space-unit-lg);
        padding-right: var(--space-unit-lg);
        margin-left: var(--space-unit-lg);
      }
    }

    .modal-content {
      padding: var(--space-unit-lg);
    }
  }
}

.confirmation-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 600px;

  @media (max-width: $mobile-breakpoint-2) {
    min-width: unset;
  }

  .confirmation-modal-question {
    margin-bottom: var(--space-unit-lg);
  }

  .confirmation-modal-options {
    display: flex;
    flex-direction: row;
    align-items: center;

    .confirmation-modal-options-cancel {
      margin-right: var(--space-unit-md);
    }
  }
}
