@import "../../../styles/constants.scss";

.grid-list {
  --item-margin: var(--space-unit-xs);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px calc(var(--item-margin) * -1); /* This removes the grid margin resulting from the flexbox wrap */
  overflow-x: auto;
  > .grid-item {
    margin: var(--item-margin);
    flex: 1 1;
    margin-bottom: var(--space-unit-md);
    min-width: 350px;
    max-width: 590px;
    @media (max-width: $mobile-breakpoint-2) {
      min-width: 270px;
    }
  }
}
