@import "../../../styles/constants.scss";

.dd-title {
  color: var(--color-keg-green);
  margin-right: var(--space-unit-xs);
  font-weight: 700;

  &.disabled {
    color: var(--color-grey-medium);
  }
}

.dd-wrapper-container {
  position: relative;
  --select-item-height: 40px;
  --select-border-radius: 10px;
  --select-border-color: var(--color-card-border);
  --select-background-color: var(--color-white);
  --select-min-width: 180px;
  --select-padding: 8px;
  --select-list-max-height: 300px;
  min-width: var(--select-min-width);
  min-height: var(--select-item-height);

  .dd-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 3;
  }
  .dd-wrapper {
    cursor: pointer;
    position: absolute;
    height: auto;
    min-height: var(--select-item-height);
    border-radius: var(--select-border-radius);
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    background-color: var(--select-background-color);
    z-index: 2;
    overflow: hidden;

    &.open {
      z-index: 4;
    }

    &.disabled {
      cursor: default;
    }

    .dd-header {
      margin: 0 var(--select-padding);
      padding: var(--select-padding) 0;
      width: calc(100% - var(--select-padding) * 2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: var(--select-item-height);
      box-sizing: border-box;

      .dd-header-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.disabled {
          color: var(--color-grey-medium);
        }
      }

      &.open {
        border-bottom: 1px solid var(--select-border-color);
      }

      .icon {
        transition: 0.2s transform;
        transform: rotateZ(0deg);
        min-width: 16px;

        &.open {
          transform: rotateZ(-180deg);
        }

        &.disabled path {
          stroke: var(--color-grey-medium);
        }
      }
    }

    .dd-list {
      max-height: var(--select-list-max-height);
      overflow-y: auto;
      width: 100%;

      .dd-list-item {
        padding: 0 var(--select-padding);
        display: flex;
        align-items: center;
        min-height: var(--select-item-height);
        color: var(--color-grey-grey100);
        .dd-list-item-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        &:hover,
        &:active {
          background-color: var(--color-grey-grey25);
        }

        &.selected {
          color: var(--color-black);
          font-weight: 700;
        }

        .MuiCheckbox-root {
          color: var(--color-black);
          padding: 0;
          margin-right: var(--space-unit-xs);
          &:hover {
            background-color: transparent !important;
          }
        }

        &.all-selected {
          color: var(--color-grey-grey100);
          font-weight: 500;
          .MuiCheckbox-root {
            color: var(--color-grey-grey100);
          }
        }
      }

      .dd-list-divider {
        margin: var(--space-unit-xxs) 0 !important;
        background-color: var(--color-grey-medium) !important;
      }
    }
  }
}
