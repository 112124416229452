@import "../../styles/constants";

@media (max-width: $mobile-breakpoint-2) {
  .page-list {
    .detail-page-header {
      .detail-page-header-content.mobile {
        display: none;
      }
    }
  }
}

.celocations-page {
  width: 100%;
  a:-webkit-any-link {
    text-decoration: none;
  }

  .celocations-table-header {
    display: flex;
    flex-direction: row;
    padding: 0 var(--space-unit-lg);
    padding-right: var(--space-unit-md);
    margin-top: var(--space-unit-lg);
  }
  .celocations-table-title {
    min-width: 120px;
    text-align: center;

    @media (max-width: $mobile-breakpoint-2) {
      font-size: 14px;
      min-width: 80px;
    }
  }
  .celocations-list {
    margin: var(--space-unit-xs) 0px;
    .item,
    .card-item {
      opacity: 0;
      animation: 0.3s forwards fade-in;
      margin-top: var(--space-unit-xs);

      @media (max-width: $mobile-breakpoint-2) {
        margin-top: var(--space-unit-md);
      }
    }

    .celocations-list-description {
      font-size: 14px;
      color: var(--color-grey-dark);
    }
  }

  &.celocation-details {
    .celocations-list {
      margin-bottom: var(--space-unit-xl);
    }
  }

  .celocation-details-list {
    display: flex;
    flex-wrap: wrap;

    .celocation-detail-group {
      margin-bottom: var(--space-unit-lg);
      margin-right: var(--space-unit-xl);
      width: 375px;

      @media (max-width: $mobile-breakpoint-1) {
        margin-right: 0;
        width: 100%;
      }

      .celocation-detail-item {
        align-items: center;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: var(--space-unit-md);

        &--missing-info {
          color: var(--color-grey-dark);
        }
      }

      .celocation-detail-key {
        font-weight: bold;
      }

      .celocation-detail-value-wrapper {
        align-items: center;
        display: flex;
        max-width: 70%;

        .icon {
          flex-shrink: 0;
        }
      }

      .celocation-detail-value {
        margin-right: var(--space-unit-xs);
        text-align: end;
      }
    }
  }

  .celocation-detail-title {
    margin-bottom: var(--space-unit-lg);
  }

  .celocation-photos {
    display: flex;
    flex-wrap: wrap;

    .celocation-photo {
      border-radius: 5px;
      margin-bottom: var(--space-unit-md);
      margin-right: var(--space-unit-md);
      max-height: 180px;
      max-width: 180px;
      cursor: pointer;
      object-fit: cover;
    }

    .celocation-add-photo {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-bottom: var(--space-unit-md);
      margin-right: var(--space-unit-md);
      min-height: 180px;
      min-width: 180px;
      background-color: var(--color-grey-dark);
      cursor: pointer;
    }

    .celocation-add-photo-hidden {
      width: 0;
      height: 0;
    }
  }

  .celocation-photo-modal {
    .modal-container {
      background-color: var(--color-black);
    }

    .celocation-photo {
      border-radius: 5px;
      max-height: 520px;
      max-width: 100%;
    }

    .celocation-button {
      border: 1px solid var(--color-white);
      background-color: var(--color-white);
      color: black;
    }

    .celocation-danger-button {
      border: 1px solid #d86464;
      color: #d86464;
      &:hover {
        background-color: #d86464;
        color: var(--color-white);
      }
    }

    .celocation-button-wrapper {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: var(--space-unit-md);

      button:first-of-type {
        margin-right: var(--space-unit-lg);
      }
    }
  }
}
